import React from 'react'

const Footer = (props) => {
    return <footer className="ftco-footer ftco-section">
    <div className="container">
        {/* <div className="row">
            <div className="mouse">
                <a href="#" className="mouse-icon">
                    <div className="mouse-wheel"><span className="ion-ios-arrow-up"></span></div>
                </a>
            </div>
        </div> */}
        <div className="row mb-5">
            <div className="col-md">
                <div className="ftco-footer-widget mb-4">
                    <h2 className="ftco-heading-2">Hygiene bites</h2>
                    <p>Freshness you can taste!</p>
                    <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                        <li className="ftco-animate"><a href="https://www.instagram.com/hygienebite/"><span
                                    className="icon-twitter"></span></a></li>
                        <li className="ftco-animate"><a href="https://www.instagram.com/hygienebite/"><span
                                    className="icon-facebook"></span></a></li>
                        <li className="ftco-animate"><a href="https://www.instagram.com/hygienebite/"><span
                                    className="icon-instagram"></span></a></li>
                    </ul>
                </div>
            </div>
            <div className="col-md">
                <div className="ftco-footer-widget mb-4 ml-md-5">
                    <h2 className="ftco-heading-2">Menu</h2>
                    <ul className="list-unstyled">
                        <li><a href="#" className="py-2 d-block">Shop</a></li>
                        <li><a href="#" className="py-2 d-block">About</a></li>
                        <li><a href="#" className="py-2 d-block">Journal</a></li>
                        <li><a href="#" className="py-2 d-block">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div className="col-md-4">
                <div className="ftco-footer-widget mb-4">
                    <h2 className="ftco-heading-2">Help</h2>
                    <div className="d-flex">
                        <ul className="list-unstyled mr-l-5 pr-l-3 mr-4">
                            <li><a href="#" className="py-2 d-block">Shipping Information</a></li>
                            <li><a href="#" className="py-2 d-block">Returns &amp; Exchange</a></li>
                            <li><a href="#" className="py-2 d-block">Terms &amp; Conditions</a></li>
                            <li><a href="#" className="py-2 d-block">Privacy Policy</a></li>
                        </ul>
                        <ul className="list-unstyled">
                            <li><a href="#" className="py-2 d-block">FAQs</a></li>
                            <li><a href="#" className="py-2 d-block">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-md">
                <div className="ftco-footer-widget mb-4">
                    <h2 className="ftco-heading-2">Have a Questions?</h2>
                    <div className="block-23 mb-3">
                        <ul>
                            <li><span className="icon icon-map-marker"></span><span className="text">2-13 ,Oppt. MRO Office,
                                    Kakinada, AP, India</span></li>
                            <li><a href="#"><span className="icon icon-phone"></span><span className="text">+91 9666 628
                                        477</span></a></li>
                            <li><a href="#"><span className="icon icon-envelope"></span><span
                                        className="text">info@hygienebites.com</span></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className='copyrights-wrapper'>
        {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
        Copyright &copy;
        <script>document.write(new Date().getFullYear());</script> All Rights Reserved | This template
        is made with <i className="icon-heart color-danger" aria-hidden="true"></i> by <a
            href="http://hygienebites.com" target="_blank">hygienebites</a>
        {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
    </div>
</footer>
}

export default Footer;