import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input/input'
import TextField from '@material-ui/core/TextField'
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import CustomPhoneNumber from './PhoneNumber';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';
import LoadingButton from '@mui/lab/LoadingButton';


const Login = (props) => {
    const [mynumber, setNumber] = useState("");
    const [otp, setotp] = useState("");
    const [show, setshow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [final, setfinal] = useState("");
    const [isNewUser, setIsNewUser] = useState(false);
    const [cartData, addProduct, removeProduct, decreaseProductQuantity,
        increaseProductQuantity,
        getCartProducts] = useUpdateCart();
    const auth = getAuth();
    auth.useDeviceLanguage();

    useEffect(() => {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                signin();
            }
        });

    }, []);

    // const setRecaptcha = () => {
    //     window.recaptchaVerifier = new RecaptchaVerifier(auth, 'sign-in-button', {
    //         'size': 'invisible',
    //         'callback': (response) => {
    //             // reCAPTCHA solved, allow signInWithPhoneNumber.
    //             signin();
    //         }
    //     });
    // }



    const signin = () => {
        if (mynumber === "" || mynumber.length < 10) return;
        console.log(mynumber);
        setLoading(true);
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, mynumber, appVerifier)
            .then((result) => {
                setfinal(result);
               // alert("code sent");
                setshow(true);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err, 'err');
                alert(err);
                window.location.reload();
            });
    };


    const ValidateOtp = () => {
        if (otp === null || final === null) return;
        final.confirm(otp).then((result) => {
            // User signed in successfully.
            const user = result.user;
            localStorage.setItem('userData', JSON.stringify(user));
            getCartProducts(user.uid);
            props.onClose(false);
            // ...
        }).catch((error) => {
            // User couldn't sign in (bad verification code?)
            // ...
        });
    };

    return <div className='container' style={{ width: '300px' }}>
        {(!isNewUser) ?
            <Paper style={{ padding: 20 }} >
                <Grid container columns={{ xs: 2, md: 12 }}>
                    <div >
                        <IconButton onClick={() => {
                            props.onClose(false)
                        }}> <CloseIcon />
                        </IconButton>

                        <center>
                            <div><h1>Sign In</h1></div>
                            <div
                                style={{
                                    display: !show ? "block" : "none",
                                }}
                            >
                                <PhoneInput
                                    placeholder='Enter phone number'
                                    country="IN"
                                    withCountryCallingCode
                                    international
                                    value={mynumber}
                                    onChange={setNumber}
                                    inputComponent={CustomPhoneNumber}

                                />
                                <br />
                                <br />
                                <div id="recaptcha-container"></div>
                                {/* <Button onClick={signin} id="sign-in-button" variant='contained'>
                                    Send OTP
                                </Button> */}
                                <LoadingButton
                                    id="sign-in-button"
                                    onClick={signin}
                                    loading={loading}
                                    loadingIndicator="Sending..."
                                    variant="contained"
                                >
                                    <span>Send OTP</span>
                                </LoadingButton>

                            </div>
                            <div
                                style={{
                                    display: show ? "block" : "none",
                                }}
                            >
                                <TextField
                                    {...props}
                                    // InputProps={{
                                    //     className: classes.input
                                    // }}
                                    fullWidth
                                    size='small'
                                    label='Enter your OTP'
                                    variant='outlined'
                                    name='otp_number'
                                    onChange={(e) => {
                                        setotp(e.target.value);
                                    }}
                                />

                                <br />
                                <br />
                                <Button onClick={ValidateOtp} variant="contained">
                                    Verify
                                </Button>
                            </div>
                        </center>

                    </div>
                </Grid>

            </Paper> : <Paper style={{ padding: 20 }}>

            </Paper>
        }
    </div>
};

export default Login;