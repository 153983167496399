import React, { useState } from "react";
import {
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    MenuItem,
    Select,
    FormGroup,
    Checkbox,
    Button
} from '@mui/material';
import { Grid } from "@mui/material";

const MaterialFormComponent = (props) => {
    const initialValues = {
        first_name: "",
        last_name: "",
        flat_no: "",
        area_street: "",
        location: "",
        city: "Kakinada",
        state: "Andhra Pradesh",
        pincode: "",
        address_type: "Work",
        is_default: ''
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const [formValues, setFormValues] = useState(initialValues);
    const handleSubmit = (event) => {
        event.preventDefault();
        const userDataObj = props.getCurrentUserData();
        props.addUser([props.userAddresses, formValues, userDataObj.uid]);
        props.onCancel();
        props.getUserAddress();
        console.log(formValues);
    };

    return (
        <>
            <h6>New Address</h6>
            <form onSubmit={handleSubmit}>
                <Grid container alignItems="left" justify="left" direction="column" >
                    <Grid container xs={12}>
                        <Grid item xs={3}>
                            <TextField
                                margin="dense"
                                id="first_name"
                                name="first_name"
                                label="First Name"
                                type="text"
                                value={formValues.first_name}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                margin="dense"
                                id="last_name"
                                name="last_name"
                                label="Last Name"
                                type="text"
                                value={formValues.last_name}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="flat_no"
                            name="flat_no"
                            label="Flat / House No., Building, Apartment"
                            type="text"
                            value={formValues.flat_no}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="area_street"
                            name="area_street"
                            label="Area, Street, Sector, Village"
                            type="text"
                            value={formValues.area_street}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            margin="dense"
                            id="location"
                            name="location"
                            label="Location / Landmark"
                            type="text"
                            value={formValues.location}
                            onChange={handleInputChange}
                        />
                    </Grid>

                    <Grid container xs={12}>
                        <Grid item xs={3}>
                            <TextField
                                margin="dense"
                                id="city"
                                name="city"
                                label="City"
                                type="text"
                                value={formValues.city}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl margin="dense">
                                <Select
                                    name="state"
                                    value={formValues.state}
                                    onChange={handleInputChange}
                                >
                                    <MenuItem key="Andhra Pradesh" value="Andhra Pradesh">
                                        Andhra Pradesh
                                    </MenuItem>
                                    <MenuItem key="Telangana" value="Telangana">
                                        Telangana
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid container xs={12}>
                            <Grid item xs={3}>
                                <TextField
                                    margin="dense"
                                    id="pincode"
                                    name="pincode"
                                    label="Pincode"
                                    type="text"
                                    value={formValues.pincode}
                                    onChange={handleInputChange}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <FormControl margin="dense">
                                    <Select
                                        name="address_type"
                                        value={formValues.address_type}
                                        onChange={handleInputChange}
                                    >
                                        <MenuItem key="Home" value="Home">
                                            Home
                                        </MenuItem>
                                        <MenuItem key="Work" value="Work">
                                            Work
                                        </MenuItem>

                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid>
                            <Grid item>
                                <FormControlLabel
                                    margin="dense"
                                    control={<Checkbox name="is_default" />} label={<FormLabel>Make this my default address</FormLabel>} />
                            </Grid>
                        </Grid>
                    </Grid>


                    <Grid item>
                        <Button variant="outlined" color="success" type="button"
                            onClick={() => props.onCancel()}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" type="submit" style={{
                            backgroundColor: "green",
                            margin: "5px"
                        }}>
                            Submit
                        </Button>

                    </Grid>
                </Grid>
            </form>
        </>
    );
}
export default MaterialFormComponent;