import React, { Fragment } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { NavLink } from 'react-router-dom';

const Categories = (props) => {
    const { selCat, categories, getProducts } = props;
    const handleChange = (event, newValue) => {
        getProducts(newValue);
    };
    return <Fragment>
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}
        variant="scrollable"
        scrollButtons={true}
        allowScrollButtonsMobile={true}
    >
        <Tabs value={selCat} onChange={handleChange} left>
            {
                categories && categories.map((cat, index) => {
                    return <Tab value={cat} label={cat?.name} key={cat?.id} />
                })
            }
        </Tabs>
    </Box>
    </Fragment>
}
export default Categories;