import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Login from "./Login";
import Grid from '@mui/material/Grid';
import useUserData from "../../Hooks/useUser/useUserdata";



const useStyles = makeStyles(() => ({
  link: {
    textDecoration: "none",
    color: "#d99308",
    fontSize: "20px",
  },
  icon: {
    color: "white"
  }
}));

const LoginDrawerComponent = (props) => {
  const [userData, showLoginDrawer, hideLoginDrawer] = useUserData();
  console.log(userData);
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(true);
  return (
    <>
      <Drawer
        color={'white'}
        open={userData.showDrawer}
        onClose={() => hideLoginDrawer(false)}
        anchor={'right'}
        sx={{ width: '400px' }}
      >

        <Grid container columns={{ xs: 2, md: 12 }}>
          <Login onClose={hideLoginDrawer} />
        </Grid>
      </Drawer>
    </>
  );
}
export default LoginDrawerComponent;