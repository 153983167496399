import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import OrderProduct from './OrderProduct';

const ProductsBlock = (props) => {
    const {products} = props;
    return <Box sx={{ width: '100%', bgcolor: 'background.paper', pt: 3 }}>
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {products && products.map((product, index) => (
        <Grid item xs={12} sm={12} md={12} key={index}>
          <OrderProduct key={'ctpr' + index} product={product} />
        </Grid>
      ))}
    </Grid>
  </Box>
}

export default ProductsBlock;