import React from 'react'
import Banner from './Banner/Banner';
import Featured from './Featured/Featured';
import Testimonials from './Testimonials/Testimonials';
import Process from './Process/Process';
import CategoriesBlock from './Categories/Categories';

const Home = (props) => {
    return <>
    <Banner/>
    <Process/>
    <CategoriesBlock/>
    <Featured/>
    <Testimonials/>
    </>
}

export default Home;