import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { NavLink } from 'react-router-dom';
import './success.scss';



const SuccessView = (props) => {
    return <div className='container' style={{ marginBottom: '5px', padding: '5px' }}>
        <div style={{ padding: 10, }}>
            <Card sx={{ width: '100%', alignContent: "center", textAlign: "center" }}>
                <CardContent >
                    <div class="success-icon">
                        <div class="success-icon__tip"></div>
                        <div class="success-icon__long"></div>
                    </div>
                    <h2>Success!</h2>
                    <div> Your order has been confirmed. Please visit the <NavLink className="" to="/orders">orders</NavLink> page to see your orders. </div>
                </CardContent>
            </Card>
        </div>
    </div>
}

export default SuccessView;