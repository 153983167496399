import React, { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Product from '../Product/Product';
import useProducts from '../../Hooks/useProducts/useProducts';
import LoadingSpinner from '../../Utils/loader';
import Categories from './Categories';



const Products = (props) => {
  const [productsPageData, getProducts] = useProducts();
  const { products,
    categories,
    selCat,
    isLoading,
    error } = productsPageData;

  useEffect(() => {
    getProducts();
  }, [])

  // console.log(productsPageData, productsPageData.selCat, 'productsDataFromHook1');
  return <Fragment>
    <div className='container products-wrapper'>
      <Categories categories={categories} selCat={selCat} getProducts={getProducts} />
      {/* products list */}
      {(isLoading ? <LoadingSpinner /> : <>
        <div className='container' pt>
          <Box sx={{ flexGrow: 1, pt: 2 }}>
            {
              (products && products.length) ? <Grid container spacing={{ xs: 2, md: 4, lg: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                {products && products.map((product, index) => (
                  <Grid item xs={6} sm={3} key={index}>
                    <Product key={'pr' + index} product={product} />
                  </Grid>
                ))}
              </Grid> : <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                There is no products available in this category.
              </Grid>
            }

          </Box>
        </div>
      </>)}

    </div>
  </Fragment>
}

export default Products;