import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


const Billing = (props) => {
    const { billingDetails } = useSelector(state => state.useUpdateCartReducer);
    const convertNumberFormat = (number) => {
        return number.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        });
    }
    return <Fragment>
        <div >
            <Paper style={{ padding: 20 }}>
                <h5>Billing Details</h5>
                <hr />
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        Total Price :
                    </Grid>
                    <Grid item xs={4}>
                        {convertNumberFormat(billingDetails.totalPrice)}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        Delivery Charges :
                    </Grid>
                    <Grid item xs={4}>
                        {convertNumberFormat(billingDetails.deliveryCharges)}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        Discount(-) :
                    </Grid>
                    <Grid item xs={4}>
                        {convertNumberFormat(billingDetails.discount)}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        Final Bill Total :
                    </Grid>
                    <Grid item xs={4}>
                        {convertNumberFormat(billingDetails.finalPrice)}
                    </Grid>
                </Grid>
            </Paper>

        </div>
    </Fragment>
}

export default Billing;