import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    TextField,
    MenuItem,
    Select,
    FormGroup,
    Checkbox,
    Button
} from '@mui/material';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);


export default function AddressCard(props) {
    const { address } = props;
    return (
        <div style={{ padding: 10, display: 'flex', flexBasis: 'row' }}>
            <Card sx={{ width: 300, display: 'flex', flexBasis: 'row', borderRadius:5 }}>
                <CardContent>
                    <CardActions>
                    <FormControlLabel
                                    margin="dense"
                                    value={address.flat_no}
                                    control={ <Radio name="is_default" value={address.flat_no} 
                                    onChange={() => props.handleAddressChange(address)} />} />
                    </CardActions>
                    <Typography variant='h5' gutterBottom>
                        {address.address_type}
                    </Typography>
                    <Typography sx={{ mb: 0 }} color="text.secondary">
                        {address?.first_name} {address?.last_name} ,
                    </Typography>
                    <Typography sx={{ mb: 0 }} color="text.secondary">
                        {address?.flat_no} ,
                    </Typography>
                    <Typography sx={{ mb: 0 }} color="text.secondary">
                        {address?.location},
                    </Typography>
                    <Typography sx={{ mb: 0 }} color="text.secondary">
                        {address?.city},
                    </Typography>
                    <Typography sx={{ mb: 0 }} color="text.secondary">
                        {address?.state},
                    </Typography>
                    <Typography sx={{ mb: 0 }} color="text.secondary">
                        {address?.pincode},
                    </Typography>
                   
                </CardContent>
                {/* <CardActions>
        <Button size="small">Card Button</Button>
      </CardActions> */}
            </Card>
        </div>
    );
}