import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import {
  BrowserRouter as Router,
  // Route, Link, Switch 
} from "react-router-dom";
import routes from "./routes";
import Footer from './Components/Footer/Footer';
import LoginDrawerComponent from './Components/Login/LoginDrawer';
import useUpdateCart from './Hooks/useUpdateCart/useUpdateCart';
import useUserData from './Hooks/useUser/useUserdata';
import { useEffect, useState } from 'react';
import useOrders from './Hooks/useOrders/useOrders';
import ScrollToTop from './Components/Common/ScrollToTop';
import { FloatingWhatsApp } from 'react-floating-whatsapp'



function App() {
  const [isUserLoggedIn, setUserLoggedIn] = useState(false);
  const [cartData, addProduct, removeProduct, decreaseProductQuantity,
    increaseProductQuantity,
    getCartProducts] = useUpdateCart();
  const [userData, showLoginDrawer, hideLoginDrawer, isUserAutheticated, getCurrentUserData, , ,getuserAddress] = useUserData();
  const [result, , getUserOrders] = useOrders();



  useEffect(() => {
    if (isUserAutheticated()) {
      const userData = getCurrentUserData();
      getCartProducts(userData.uid);
      getuserAddress(userData.uid);
      getUserOrders(userData.uid);

    }

  }, []);
 
  return (
    <Router>
       <ScrollToTop />
      <section className="goto-here">
        <Header />
        {routes}
        <FloatingWhatsApp 
        phoneNumber={'8121298899'}
        accountName	={'Hygiene Bites'}
        chatMessage={'Hello there! 🤝 \nHow can we help?'}
        statusMessage={'Online'}
        />
        <Footer />
        <LoginDrawerComponent />
      </section>
    </Router>
  );
}

export default App;
