import React from "react";
import {
    AppBar,
    Toolbar,
    CssBaseline,
    makeStyles,
    useTheme,
    useMediaQuery,
} from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Badge from '@mui/material/Badge';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DrawerComponent from "./Drawer";
import { useSelector } from 'react-redux';
import useUserData from "../../Hooks/useUser/useUserdata";
import useUpdateCart from "../../Hooks/useUpdateCart/useUpdateCart";
import { useNavigate } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    MuiAppBar: {
        backgroundColor: 'white'
    },
    navlinks: {
        marginLeft: theme.spacing(10),
        display: 'block',
        padding: '0.5rem 1rem'
    },
    logo: {
        flexGrow: "1",
        cursor: "pointer",
    },
    link: {
        textDecoration: "none",
        color: "#d99308",
        fontSize: "20px",
        marginLeft: theme.spacing(20),
        "&:hover": {
            color: "white",
            textDecoration: 'none'
        },
    },
    overrides: { MuiAppBar: { colorPrimary: { backgroundColor: 'white' }, root: { border: 0, borderTop: 10, borderTopColor: 'white', borderTopStyle: "solid" } } }
}));

const Navbar = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const result = useSelector(state => state.useUpdateCartReducer);
    const [cartData, addProduct, removeProduct, decreaseProductQuantity,
        increaseProductQuantity,
        getCartProducts, resetCartData] = useUpdateCart();
    const [userData, showLoginDrawer, hideLoginDrawer, isUserAutheticated, getCurrentUserData, logout] = useUserData();
    const navigate = useNavigate();


    const handleLogout = () => {
        resetCartData();
        logout();
        navigate("/");

    }
    const marginTop = (isMobile) ? '90px' : '90px';
    console.log(isMobile, '---ismonile');
    return (<section style={{ marginTop: marginTop }}><AppBar type='sticky' color='white'>
        <CssBaseline />
        <div className="help-section">
            <div className="container">
                <div className="row no-gutters d-flex align-items-start align-items-center px-md-0">
                    <div className="col-lg-12 d-block">
                        <div className="row d-flex">
                            <div className="col-md pr-4 d-flex topper align-items-center">
                                <div className="icon mr-2 d-flex justify-content-center align-items-center"><span
                                    className="icon-phone2"></span></div>
                                <span className="text">+ 8121298899</span>
                            </div>
                            <div className="col-md pr-4 d-flex topper align-items-center">
                                <div className="icon mr-2 d-flex justify-content-center align-items-center"><span
                                    className="icon-paper-plane"></span></div>
                                <span className="text">support@hygienebites.com</span>
                            </div>
                            <div className="col-md-5 pr-4 d-flex topper align-items-center text-lg-right">
                                <span className="text">Same day delivery &amp; Free Returns</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Toolbar className="navbar navbar-expand-lg ftco_navbar ftco-navbar-light" id="ftco-navbar">
            <NavLink to="/"
                className={({ isActive }) => (isActive ? "navbar-brand" : 'navbar-brand')}
            >
                Hygiene Bites
            </NavLink>
            {isMobile ? (
                <DrawerComponent
                    cartTotal={result.count}
                    showLoginDrawer={showLoginDrawer}
                    isUserAutheticated={isUserAutheticated}
                />
            ) : (
                <div className=" navbar-collapse" id="ftco-nav">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <NavLink exact to="/"
                                className="nav-link">
                                Home
                            </NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/products">
                                Products
                            </NavLink></li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/about-us">
                                About
                            </NavLink></li>

                        <li className="nav-item">
                            <NavLink className="nav-link" to="/contact-us">
                                Contact
                            </NavLink>
                        </li>
                        {
                            (isUserAutheticated()) ? (<><li className="nav-item">
                                <NavLink className="nav-link" to="/orders">
                                    Orders
                                </NavLink>
                            </li>
                                <li className="nav-item">

                                    <a className="nav-link" onClick={() => { handleLogout() }}>
                                        Logout
                                    </a>
                                </li>



                                <li className="nav-item cta cta-colored">


                                    <NavLink className="nav-link" to="/cart">
                                        <Badge badgeContent={result.count} color="success">
                                            <AddShoppingCartIcon />
                                        </Badge>
                                    </NavLink>


                                </li>
                            </>
                            ) : <li className="nav-item">
                                <a className="nav-link" onClick={() => { showLoginDrawer() }}>
                                    Login
                                </a>
                            </li>

                        }



                    </ul>
                </div>
            )}
        </Toolbar>
    </AppBar>
    </section>
    );
}
export default Navbar;