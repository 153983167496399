import React from 'react';
const prodImage1 = require('../../../images/product-1.jpg');
const prodImage2 = require('../../../images/product-2.jpg');
const prodImage3 = require('../../../images/product-3.jpg');
const prodImage4 = require('../../../images/product-4.jpg');
const prodImage5 = require('../../../images/product-5.jpg');
const prodImage6 = require('../../../images/product-6.jpg');
const prodImage7 = require('../../../images/product-7.jpg');
const prodImage8 = require('../../../images/product-8.jpg');

const Featured = (props) => {
    return <>
    <section className="ftco-section">
		<div className="container">
			<div className="row justify-content-center mb-3 pb-3">
				<div className="col-md-12 heading-section text-center ">
					<span className="subheading">Featured Products</span>
					<h2 className="mb-4">Our Products</h2>
					<p>Fresh Picks, Delivered to Your Doorstep – Nature's Best, Just a Click Away! </p>
				</div>
			</div>
		</div>
		<div className="container">
			<div className="row">
				<div className="col-md-6 col-lg-3 ">
					<div className="product">
						<a href="#" className="img-prod"><img className="img-fluid" src={prodImage1}
								alt="Colorlib Template"/>
							<span className="status">30%</span>
							<div className="overlay"></div>
						</a>
						<div className="text py-3 pb-4 px-3 text-center">
							<h3><a href="#">Bell Pepper</a></h3>
							<div className="d-flex">
								<div className="pricing">
									<p className="price"><span className="price-sale">out of stock</span></p>
								</div>
							</div>
							<div className="bottom-area d-flex px-3">
								<div className="m-auto d-flex">
									<a href="#"
										className="add-to-cart d-flex justify-content-center align-items-center text-center">
										<span><i className="ion-ios-menu"></i></span>
									</a>
									<a href="#" className="buy-now d-flex justify-content-center align-items-center mx-1">
										<span><i className="ion-ios-cart"></i></span>
									</a>
									<a href="#" className="heart d-flex justify-content-center align-items-center ">
										<span><i className="ion-ios-heart"></i></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3 ">
					<div className="product">
						<a href="#" className="img-prod"><img className="img-fluid" src={prodImage2}
								alt="Colorlib Template"/>
							<div className="overlay"></div>
						</a>
						<div className="text py-3 pb-4 px-3 text-center">
							<h3><a href="#">Strawberry</a></h3>
							<div className="d-flex">
								<div className="pricing">
									<p className="price"><span className="price-sale">out of stock</span></p>
								</div>
							</div>
							<div className="bottom-area d-flex px-3">
								<div className="m-auto d-flex">
									<a href="#"
										className="add-to-cart d-flex justify-content-center align-items-center text-center">
										<span><i className="ion-ios-menu"></i></span>
									</a>
									<a href="#" className="buy-now d-flex justify-content-center align-items-center mx-1">
										<span><i className="ion-ios-cart"></i></span>
									</a>
									<a href="#" className="heart d-flex justify-content-center align-items-center ">
										<span><i className="ion-ios-heart"></i></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3 ">
					<div className="product">
						<a href="#" className="img-prod"><img className="img-fluid" src={prodImage3}
								alt="Colorlib Template"/>
							<div className="overlay"></div>
						</a>
						<div className="text py-3 pb-4 px-3 text-center">
							<h3><a href="#">Green Beans</a></h3>
							<div className="d-flex">
								<div className="pricing">
									<p className="price"><span className="price-sale">out of stock</span></p>
								</div>
							</div>
							<div className="bottom-area d-flex px-3">
								<div className="m-auto d-flex">
									<a href="#"
										className="add-to-cart d-flex justify-content-center align-items-center text-center">
										<span><i className="ion-ios-menu"></i></span>
									</a>
									<a href="#" className="buy-now d-flex justify-content-center align-items-center mx-1">
										<span><i className="ion-ios-cart"></i></span>
									</a>
									<a href="#" className="heart d-flex justify-content-center align-items-center ">
										<span><i className="ion-ios-heart"></i></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3 ">
					<div className="product">
						<a href="#" className="img-prod"><img className="img-fluid" src={prodImage4}
								alt="Colorlib Template"/>
							<div className="overlay"></div>
						</a>
						<div className="text py-3 pb-4 px-3 text-center">
							<h3><a href="#">Purple Cabbage</a></h3>
							<div className="d-flex">
								<div className="pricing">
									<p className="price"><span className="price-sale">out of stock</span></p>
								</div>
							</div>
							<div className="bottom-area d-flex px-3">
								<div className="m-auto d-flex">
									<a href="#"
										className="add-to-cart d-flex justify-content-center align-items-center text-center">
										<span><i className="ion-ios-menu"></i></span>
									</a>
									<a href="#" className="buy-now d-flex justify-content-center align-items-center mx-1">
										<span><i className="ion-ios-cart"></i></span>
									</a>
									<a href="#" className="heart d-flex justify-content-center align-items-center ">
										<span><i className="ion-ios-heart"></i></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>


				<div className="col-md-6 col-lg-3 ">
					<div className="product">
						<a href="#" className="img-prod"><img className="img-fluid" src={prodImage5}
								alt="Colorlib Template"/>
							<span className="status">30%</span>
							<div className="overlay"></div>
						</a>
						<div className="text py-3 pb-4 px-3 text-center">
							<h3><a href="#">Tomatoe</a></h3>
							<div className="d-flex">
								<div className="pricing">
									<p className="price"><span className="price-sale">out of stock</span></p>
								</div>
							</div>
							<div className="bottom-area d-flex px-3">
								<div className="m-auto d-flex">
									<a href="#"
										className="add-to-cart d-flex justify-content-center align-items-center text-center">
										<span><i className="ion-ios-menu"></i></span>
									</a>
									<a href="#" className="buy-now d-flex justify-content-center align-items-center mx-1">
										<span><i className="ion-ios-cart"></i></span>
									</a>
									<a href="#" className="heart d-flex justify-content-center align-items-center ">
										<span><i className="ion-ios-heart"></i></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3 ">
					<div className="product">
						<a href="#" className="img-prod"><img className="img-fluid" src={prodImage6}
								alt="Colorlib Template"/>
							<div className="overlay"></div>
						</a>
						<div className="text py-3 pb-4 px-3 text-center">
							<h3><a href="#">Brocolli</a></h3>
							<div className="d-flex">
								<div className="pricing">
									<p className="price"><span className="price-sale">out of stock</span></p>
								</div>
							</div>
							<div className="bottom-area d-flex px-3">
								<div className="m-auto d-flex">
									<a href="#"
										className="add-to-cart d-flex justify-content-center align-items-center text-center">
										<span><i className="ion-ios-menu"></i></span>
									</a>
									<a href="#" className="buy-now d-flex justify-content-center align-items-center mx-1">
										<span><i className="ion-ios-cart"></i></span>
									</a>
									<a href="#" className="heart d-flex justify-content-center align-items-center ">
										<span><i className="ion-ios-heart"></i></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3 ">
					<div className="product">
						<a href="#" className="img-prod"><img className="img-fluid" src={prodImage7}
								alt="Colorlib Template"/>
							<div className="overlay"></div>
						</a>
						<div className="text py-3 pb-4 px-3 text-center">
							<h3><a href="#">Carrots</a></h3>
							<div className="d-flex">
								<div className="pricing">
									<p className="price"><span className="price-sale">out of stock</span></p>
								</div>
							</div>
							<div className="bottom-area d-flex px-3">
								<div className="m-auto d-flex">
									<a href="#"
										className="add-to-cart d-flex justify-content-center align-items-center text-center">
										<span><i className="ion-ios-menu"></i></span>
									</a>
									<a href="#" className="buy-now d-flex justify-content-center align-items-center mx-1">
										<span><i className="ion-ios-cart"></i></span>
									</a>
									<a href="#" className="heart d-flex justify-content-center align-items-center ">
										<span><i className="ion-ios-heart"></i></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-6 col-lg-3 ">
					<div className="product">
						<a href="#" className="img-prod"><img className="img-fluid" src={prodImage8}
								alt="Colorlib Template"/>
							<div className="overlay"></div>
						</a>
						<div className="text py-3 pb-4 px-3 text-center">
							<h3><a href="#">Fruit Juice</a></h3>
							<div className="d-flex">
								<div className="pricing">
									<p className="price"><span className="price-sale">out of stock</span></p>
								</div>
							</div>
							<div className="bottom-area d-flex px-3">
								<div className="m-auto d-flex">
									<a href="#"
										className="add-to-cart d-flex justify-content-center align-items-center text-center">
										<span><i className="ion-ios-menu"></i></span>
									</a>
									<a href="#" className="buy-now d-flex justify-content-center align-items-center mx-1">
										<span><i className="ion-ios-cart"></i></span>
									</a>
									<a href="#" className="heart d-flex justify-content-center align-items-center ">
										<span><i className="ion-ios-heart"></i></span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    </>
}

export default Featured;