import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom"
import OrderService from '../../Services/Orders'
import useUserData from '../../Hooks/useUser/useUserdata'
import FinalBilling from '../Cart/FinalBilling'
import CartProduct from '../Cart/Cartproduct'
import ProductsBlock from './ProductsBlock'
import OrderBill from '../Orders/OrderBill'
import { useNavigate } from 'react-router-dom';


const Header = (props) => {
    const location = useLocation();
    const [orderId, setOrderId] = useState(null);
    const [orderDetails, setOrderDetails] = useState({});
    const navigate = useNavigate();
    const [result,
        ,
        ,
        ,
        getCurrentUserData,
        ,
        updateUserAddress,
        getuserAddress, setSelectedAddress] = useUserData();
    useEffect(() => {
        if (location) {
            let tmp = location.pathname.slice(location.pathname.lastIndexOf("/") + 1, location.pathname.length);
            setOrderId(tmp);
        }
    }, []);

    useEffect(() => {
        if (orderId) {
            const userDataObj = getCurrentUserData();
            OrderService.getUserOrderById(userDataObj.uid, orderId).then(resp => {
                if (resp.orderDetails) {
                    setOrderDetails(resp.orderDetails);
                }

            }).catch((err) => {
                console.log('error');
            })
        }
    }, [orderId]);
    console.log(orderId);

    const handleStatusChange = () => {
        if (orderId) {
            const userDataObj = getCurrentUserData();
            OrderService.cancelOrder(userDataObj.uid, orderId).then(resp => {
                navigate("/orders");
            }).catch((err) => {
                console.log('error');
            })
        }
    }
    return <div className='container' style={{ marginBottom: '5px', padding: '5px' }}>
        {
            orderDetails && orderDetails.products ? <div>
                <h4>Order Details</h4>
                <ProductsBlock products={orderDetails.products} />
                <OrderBill
                    billingDetails={orderDetails.billingDetails}
                    address={orderDetails.deliveryAddress}
                    orderStatus={orderDetails.orderStatus}
                    handleStatusChange={handleStatusChange}
                />
            </div> : <div> Data Not Found.</div>
        }</div>
}

export default Header;