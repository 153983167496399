import React, { Fragment, useEffect } from 'react'
import useOrders from '../../Hooks/useOrders/useOrders';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CartProduct from '../Cart/Cartproduct';
import { NavLink } from 'react-router-dom';
import OrderProduct from './orderProduct';
import { DataGrid, GridActionsCellItem, } from '@mui/x-data-grid';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate } from 'react-router-dom';



const Orders = (props) => {
    const [ordersData, , getUserOrders] = useOrders();
    const navigate = useNavigate();
    const columns = [
        { field: 'id', headerName: 'ORDER ID', width: 150 },
        { field: 'products', headerName: 'Products', width: 250 },
        { field: 'address', headerName: 'Delivery Address', width: 250 },
        { field: 'order_date', headerName: 'Ordered Date', width: 130 },
        { field: 'status', headerName: 'Order Status', width: 130 },
        { field: 'action', headerName: 'action', width: 130 },
    ];
    let orders = [];
    //   const {orders} = ordersData;
    useEffect(() => {
    }, [ordersData])

    console.log(orders.length);
    const getProductTitle = (products) => {
        let title = '';
        products && products.length && products.map(product => {
            title += product.title + ',';
        });
        return title;
    }
    const getAddress = (deliveryAddress) => {
        return `${deliveryAddress?.first_name} ${deliveryAddress?.last_name}, ${deliveryAddress?.address_type}, ${deliveryAddress?.area_street}, ${deliveryAddress?.city}, 
        ${deliveryAddress?.flat_no}, ${deliveryAddress?.location},
        ${deliveryAddress?.state} - ${deliveryAddress?.pincode},`;
    }

    const getviewButton = () => {
        return (<GridActionsCellItem
            icon={<SaveIcon />}
            label="Save"
            sx={{
                color: 'primary.main',
            }}
            onClick={() => { }}
        />)
    }
    console.log(Object.keys(ordersData.orders).length, 'Object.keys(ordersData.orders)');
    if (Object.keys(ordersData.orders).length > 0) {
        let totalorders = Object.keys(ordersData.orders).map(key => {
            return ordersData.orders[key];
        });
        orders = totalorders && totalorders.length && totalorders.map(order => {
            return {
                id: order?.orderId,
                products: getProductTitle(order?.orderDetails?.products),
                address: getAddress(order?.orderDetails?.deliveryAddress),
                order_date: order?.orderDetails?.orderedDate,
                status: order?.orderDetails?.orderStatus,
                action: 'View'
            }
        });
    }


    const handleRowClick = (event) => {
        navigate("/order/" + event.id);
    }
    return (
        <div className='container' style={{  width: '100%' }}>
            <div><h3>Orders</h3></div>
            {
                (orders && orders.length) ? <DataGrid
                    rows={orders}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                    onRowClick={(event) => handleRowClick(event)}
                    sx={{
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                        }
                    }}

                /> : <div> No Orders found. go to <NavLink className="" to="/products">Products</NavLink> Page.</div>
            }


        </div>
    );
}

export default Orders;