import React, { Fragment } from 'react';
import HorizontalLinearStepper from './stepper';

const NewCart = () => {
    return <Fragment><div className='container' style={{ marginBottom: '5px', padding: '5px' }}>
        <HorizontalLinearStepper />
    </div>
    </Fragment>
}

export default NewCart;