import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
const catImage1 = require('../../../images/person_1.jpg');
const catImage2 = require('../../../images/person_2.jpg');
const catImage3 = require('../../../images/person_3.jpg');

const Testimonials = (props) => {
    return <>
    <section className="ftco-section testimony-section">
		<div className="container">
		
					<Carousel 
           // style={{width:"100%", height:"200px !important"}}
            autoPlay={true}
            infiniteLoop={true}
            // interval={3000}
            showArrows={false}
            showIndicators={true}
            showThumbs={false}
            swipeable={true}
            
            >
              <div className="item">
                <div className="testimony-wrap p-4 pb-5">
                  <div className="user-img mb-5" style={{backgroundImage: `url(${catImage1})` }}>
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left"></i>
                    </span>
                  </div>
                  <div className="text text-center">
                    <p className="mb-5 pl-4 line">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Garreth Smith</p>
                    <span className="position">Marketing Manager</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap p-4 pb-5">
                  <div className="user-img mb-5" style={{backgroundImage: `url(${catImage2})` }}>
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left"></i>
                    </span>
                  </div>
                  <div className="text text-center">
                    <p className="mb-5 pl-4 line">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Garreth Smith</p>
                    <span className="position">Interface Designer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap p-4 pb-5">
                  <div className="user-img mb-5" style={{backgroundImage: `url(${catImage3})` }}>
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left"></i>
                    </span>
                  </div>
                  <div className="text text-center">
                    <p className="mb-5 pl-4 line">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Garreth Smith</p>
                    <span className="position">UI Designer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap p-4 pb-5">
                  <div className="user-img mb-5" style={{backgroundImage: `url(${catImage1})` }}>
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left"></i>
                    </span>
                  </div>
                  <div className="text text-center">
                    <p className="mb-5 pl-4 line">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Garreth Smith</p>
                    <span className="position">Web Developer</span>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap p-4 pb-5">
                  <div className="user-img mb-5" style={{backgroundImage: `url(${catImage1})` }}>
                    <span className="quote d-flex align-items-center justify-content-center">
                      <i className="icon-quote-left"></i>
                    </span>
                  </div>
                  <div className="text text-center">
                    <p className="mb-5 pl-4 line">Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.</p>
                    <p className="name">Garreth Smith</p>
                    <span className="position">System Analyst</span>
                  </div>
                </div>
              </div>
            </Carousel>
			
		</div>
	</section>
    </>
}

export default Testimonials;