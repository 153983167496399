import React, { Fragment, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CartProduct from './Cartproduct';
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';
import Billing from './Billing';
import { NavLink } from "react-router-dom";
import AddressBlock from './Address';
import Button from '@mui/material/Button';
import HorizontalLinearStepper from './stepper';

const Cart = (props) => {
  const [cartData] = useUpdateCart();
  const cartItems = [...cartData.products];
  return <Fragment><div className='container' style={{ marginBottom: '5px', padding: '5px' }}>
    {
      (cartData.count) ? <><Box sx={{ width: '100%', bgcolor: 'background.paper', pt: 3 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          {cartItems && cartItems.map((product, index) => (
            <Grid item xs={12} sm={12} md={12} key={index}>
              <CartProduct key={'ctpr' + index} product={product} />
            </Grid>
          ))}
        </Grid>
      </Box>
        <Billing />
      </> : <div> No Items Availabe in your cart. go to <NavLink className="" to="/products">Products</NavLink> Page.</div>
    }
  </div>
  </Fragment>
}

export default Cart;