import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import MaterialFormComponent from "./NewAddressForm";
import useUserData from "../../Hooks/useUser/useUserdata";
import AddressCard from "./AddressCard";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import FinalBilling from "./FinalBilling";
import useOrders from "../../Hooks/useOrders/useOrders";
import LoadingSpinner from "../../Utils/loader";
import { useNavigate } from 'react-router-dom';


const AddressBlock = (props) => {
    const [showNewAddress, setshowNewAddress] = useState(false);
    const [addressSelected, setaddressSelected] = useState({});
    const [isAddressSelected, setisAddressSelected] = useState(false);
    const navigate = useNavigate();

    const [result,
        ,
        ,
        ,
        getCurrentUserData,
        ,
        updateUserAddress,
        getuserAddress, setSelectedAddress] = useUserData();
    const [orderDetails, createOrder, getUserOrders] = useOrders();

    console.log(result, 'userData');

    useEffect(() => {
        if (orderDetails.isOrderCreated) {
            const userDataObj = getCurrentUserData();
            getUserOrders(userDataObj.uid);
            navigate("/orders");
        }
    }, [orderDetails.isOrderCreated]);
    useEffect(() => {
        const userData = getCurrentUserData();
        getuserAddress(userData.uid);
    }, []);

    const userAddress = result.userAddress;

    const handleAddressChange = (value) => {
        console.log(value, 'event ');
        setaddressSelected(value);
        setSelectedAddress(value);
        // setisAddressSelected(true);
        setshowNewAddress(false);
    }

    const handleChangeDeliveryAddress = () => {
        setisAddressSelected(false);
        setshowNewAddress(false);

    }

    const handleCancelButton = () => {
        setshowNewAddress(false);
    }

    const renderButton = () => {
        return <>{
            (!showNewAddress) ? <Button
                variant="outlined"
                color="success"
                onClick={() => setshowNewAddress(true)}>
                Add New Address
            </Button> : null}</>
    }

    const renderAddress = (userAddress) => {
        return <Box sx={{ flexGrow: 1, pt: 2 }}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue=""
                name="radio-buttons-group"
            >
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {userAddress.map(address => <AddressCard
                        address={address}
                        handleAddressChange={handleAddressChange}
                    />)}
                </Grid>
            </RadioGroup>
        </Box >
    }
    return <div className='container' style={{ marginBottom: '5px', padding: '5px' }}>
        {
            (showNewAddress) ? <div><MaterialFormComponent
                addUser={updateUserAddress}
                getUserAddress={getuserAddress}
                userAddresses={result.userAddress}
                getCurrentUserData={getCurrentUserData}
                onCancel={handleCancelButton}

            /> </div> : null
        }

        {
            (!showNewAddress && !isAddressSelected && (userAddress && userAddress.length)) ? renderAddress(userAddress) : null
        }
        <div>
            {renderButton()}
        </div>
    </div>


}

export default AddressBlock;