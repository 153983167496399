import React from 'react'
const catImage1 = require('../../../images/category.jpg');
const catImage2 = require('../../../images/category-1.jpg');
const catImage3 = require('../../../images/category-2.jpg');
const catImage4 = require('../../../images/category-3.jpg');
const catImage5 = require('../../../images/category-4.jpg');

const CategoriesBlock = (props) => {
    return <>
    <section className="ftco-section ftco-category ftco-no-pt">
		<div className="container">
			<div className="row">
				<div className="col-md-8">
					<div className="row">
						<div className="col-md-6 order-md-last align-items-stretch d-flex">
							<div className="category-wrap-2  img align-self-stretch d-flex"
								style={{backgroundImage: `url(${catImage1})` }}>
								<div className="text text-center">
									<h2>Vegetables</h2>
									<p>Protect the health of every home</p>
									{/* <!-- <p><a href="#" className="btn btn-primary">Shop now</a></p> --> */}
									<p><a href="#" className="btn btn-primary">Coming soon</a></p>
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="category-wrap  img mb-4 d-flex align-items-end"
								style={{backgroundImage: `url(${catImage2})` }}>
								<div className="text px-3 py-1">
									<h2 className="mb-0"><a href="#">Fruits</a></h2>
								</div>
							</div>
							<div className="category-wrap  img d-flex align-items-end"
								   style={{backgroundImage: `url(${catImage3})` }}>
								<div className="text px-3 py-1">
									<h2 className="mb-0"><a href="#">Vegetables</a></h2>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="col-md-4">
					<div className="category-wrap  img mb-4 d-flex align-items-end"
						    style={{backgroundImage: `url(${catImage4})` }}>
						<div className="text px-3 py-1">
							<h2 className="mb-0"><a href="#">Juices</a></h2>
						</div>
					</div>
					<div className="category-wrap  img d-flex align-items-end"
                           style={{backgroundImage: `url(${catImage5})` }}>
						<div className="text px-3 py-1">
							<h2 className="mb-0"><a href="#">Dried</a></h2>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
        </>
}

export default CategoriesBlock;