import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';


const OrderBill = (props) => {
    const { billingDetails, address, orderStatus, handleStatusChange } = props
    const convertNumberFormat = (number) => {
        return number.toLocaleString('en-IN', {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'INR'
        });
    }
    return <Fragment>
        <div >
            <Paper style={{ padding: 20 }}>
                <h5>Billing Details</h5>
                <hr />
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        Total Price :
                    </Grid>
                    <Grid item xs={4}>
                        {convertNumberFormat(billingDetails?.totalPrice)}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        Delivery Charges :
                    </Grid>
                    <Grid item xs={4}>
                        {convertNumberFormat(billingDetails?.deliveryCharges)}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        Discount(-) :
                    </Grid>
                    <Grid item xs={4}>
                        {convertNumberFormat(billingDetails?.discount)}
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        Final Bill Total :
                    </Grid>
                    <Grid item xs={4}>
                        {convertNumberFormat(billingDetails?.finalPrice)}
                    </Grid>
                </Grid>

                <hr />
                {/* Addressblock */}
                <Grid container spacing={2}>
                    <Grid item xs={2} spacing={2}>
                        <h5>Address Details</h5>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>
                    <Grid item xs={12} spacing={2}>
                        {address.address_type},
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        {address.flat_no} ,
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {address.location},
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {address.city},
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {address.state},
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {address.pincode}
                    </Grid>

                </Grid>
                <hr />
                <Grid container spacing={2}>
                    <Grid item xs={2} spacing={2}>
                        <h5>Order Status</h5>
                    </Grid>
                </Grid>


                <Grid container spacing={2}>
                    <Grid item xs={6} spacing={2}>
                        {orderStatus}
                    </Grid>
                    <Grid item xs={6}
                        style={{ display: (orderStatus != 'Cancelled') ? 'block' : 'none' }}
                    >
                        <Button variant='outlined' color="error" onClick={() => handleStatusChange()}> Cancel Order</Button>
                    </Grid>
                </Grid>

            </Paper>

        </div>
    </Fragment>
}

export default OrderBill;