import React, { useState, Fragment } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import useUpdateCart from '../../Hooks/useUpdateCart/useUpdateCart';
import Toast from '../../Utils/toast';
import useUserData from '../../Hooks/useUser/useUserdata';

const OrderProduct = (props) => {
    const [isAddedTocart, setIsAddedTocart] = useState(false);
    const { product } = props;
    const [cartData, addProduct, removeProduct, decreaseProductQuantity, increaseProductQuantity] = useUpdateCart();
    const [, , , isUserAutheticated, getCurrentUserData] = useUserData();

    const handleProductActions = (type, product) => {
        const userDataObj = isUserAutheticated() ? getCurrentUserData() : null;
        switch (type) {
            case 'increase':
                increaseProductQuantity([cartData.products, product, userDataObj.uid]);
                break;
            case 'decrease':
                decreaseProductQuantity([cartData.products, product, userDataObj.uid]);
                break;
            default:
                removeProduct([cartData.products, product, userDataObj.uid]);
        }
    }

    const handleRemoveProduct = (product) => {
        if (isUserAutheticated() == true) {
            const userDataObj = getCurrentUserData();
            removeProduct([cartData.products, product, userDataObj.uid]);
        }
    }

    const handleDecreaseProductQuantity = (product) => {
        if (isUserAutheticated() == true) {
            const userDataObj = getCurrentUserData();
            decreaseProductQuantity([cartData.products, product, userDataObj.uid]);
        }

    }

    const handleIncreaseProductQuantity = (product) => {
        if (isUserAutheticated() == true) {
            const userDataObj = getCurrentUserData();
            increaseProductQuantity([cartData.products, product, userDataObj.uid]);
        }
    }

    return <Fragment>
        <Card sx={{ maxWidth: '100%', maxHeight: '200px', display: 'flex' }}>
            <CardMedia
                component="img"
                sx={{ width: 151, p: 2 }}
                image={product.images[0]}
                alt="Paella dish"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>


                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography gutterBottom variant="small" component="div">
                        {product.title}
                    </Typography>
                </CardContent>


                <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                    <CardActions >
                        <CardContent>
                            <Typography variant="h6" component="div" color="grey" >
                                QTY:   {product.quantity}
                            </Typography>
                            <Typography variant="h6" component="div" color="grey" >
                                Price:   {product.price}
                            </Typography>
                        </CardContent>
                    </CardActions>
                </Box>
            </Box>
        </Card>
    </Fragment>

}

export default OrderProduct;